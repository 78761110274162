import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import ContentWrap from '../shared/ContentWrap';
import {fetchDiscoveryData, fetchDiscoveryUserRegion} from 'ducks/tplacehome/slice';
import {useOnce} from 'hooks/useOnce';
import DiscoveryDelicious from './DiscoveryDelicious';
import DiscoveryWeekend from './DiscoveryWeekend';
import DiscoverySimilar from './DiscoverySimilar';
import DiscoveryTrip from './DiscoveryTrip';
import TopInfoBox from '../shared/TopInfoBox';
import DiscoveryRegionList from './DiscoveryRegionList';
import DiscoverySkeleton from './DiscoverySkeleton';
import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import DiscoveryTmapAI from './DiscoveryTmapAI';
import Spacer from './Spacer';
import DiscoveryWrap from './DiscoveryWrap';
import {
  DISCOVERY_BANNER_PROVIDER_CONFIG,
  DISCOVERY_BANNER_SECOND_PROVIDER_CONFIG,
} from 'constant/Ads';
import {ECurationViewType} from 'ducks/remote/type';
import TPlaceCuration from '../TPlaceCuration';

import {isEmpty} from 'utils/lodash';
import TPlaceAdBanner from '../shared/TPlaceAdBanner';

import {ETPlaceTab} from 'ducks/tplacehome/types';
import {getCurrentPackageVersion} from 'utils/tmapUtils';
import usePlaceHome from 'hooks/usePlaceHome';
import InView from 'react-intersection-observer';

const AD_BANNER_FIRST_INDEX = 0;

const SpacerGray = () => {
  return <div style={{height: '8px', backgroundColor: '#EEF0F3'}} />;
};

const Discovery = () => {
  const {tplacehome, remote} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();
  const [initLoad, setInitLoad] = useState(false);

  const curationMap = useMemo(
    () =>
      remote.tplaceContentsList
        .filter((v) => v.viewType === ECurationViewType.DISCOVER)
        .reduce(
          (obj, v) => ({
            ...obj,
            [v.order]: [...(obj[v.order] || []), v],
          }),
          {}
        ),
    [remote.tplaceContentsList]
  );

  /**
   * initial load
   * 현재 사용자 위치에 대한 발견전용 geo 정보
   */
  useOnce(
    tplacehome.initialDataLoaded && tplacehome.currentTab === ETPlaceTab.DISCOVERY,
    // tplacehome.initialDataLoaded,
    async () => {
      await dispatch(fetchDiscoveryUserRegion({}));
      await dispatch(fetchDiscoveryData({}));
      setInitLoad(true);
    }
  );

  /** 렌더 조건 계산 및 배열에 담기
   */
  const contentComponentList = useMemo(() => {
    const resultData = tplacehome.discoveryData.result.data;
    const result = [] as ReactNode[];
    // 내 취향. 2개 이상인 경우
    if (resultData.similarList.length >= 2) {
      result.push(<DiscoverySimilar />);
    }
    // 주말나들이. 1개라도 있으면 노출
    if (!isEmpty(resultData.weekendList)) {
      result.push(<DiscoveryWeekend />);
    }
    // 음식점, 카페. 두개 모두 poi정보가 있어야 한다.
    if (
      !isEmpty(resultData.deliciousData.cafes) &&
      !isEmpty(resultData.deliciousData.restaurants)
    ) {
      result.push(<DiscoveryDelicious />);
    }
    // 여행. 각 항목당 2개 이상 엘리먼트가 있는 경우
    if (Object.keys(resultData.tripData).some((key) => resultData.tripData[key].length >= 2)) {
      result.push(<DiscoveryTrip />);
    }
    return result;
  }, [tplacehome.discoveryData.result.data]);

  const getAfterContentComp = useCallback(
    (index: number) => {
      if (index === AD_BANNER_FIRST_INDEX) {
        return <TPlaceAdBanner adOption={DISCOVERY_BANNER_PROVIDER_CONFIG} />;
      }

      // 마지막인경우. 1개인 경우도 해당
      if (index === contentComponentList.length - 1) {
        return (
          <>
            <Spacer />
            <InView onChange={(isView) => isView && placehomeHook.sendEvent('view.tmapranking')}>
              {/* first-of-type 적용되서 추가 */}
              <SpacerWhite height="16px" />
              <DiscoveryRegionList key="discovery_region" />
            </InView>
            <TPlaceAdBanner adOption={DISCOVERY_BANNER_SECOND_PROVIDER_CONFIG} />
          </>
        );
      }

      if (curationMap[index]?.length > 0) {
        return (
          <ul>
            {curationMap[index].map((v) => (
              <li key={v.id}>
                <InView
                  onChange={(isView) =>
                    isView &&
                    placehomeHook.sendEvent('view.curating_contents', {
                      curating_content_type: v.type,
                    })
                  }
                >
                  <SpacerGray />
                  <TPlaceCuration item={v} />
                </InView>
              </li>
            ))}
            <SpacerGray />
          </ul>
        );
      }

      // 그 외
      return <Spacer />;
    },
    [contentComponentList.length, curationMap, placehomeHook]
  );

  // 여행 재 로드시 스크롤 위치 조정
  const [contentScrollTop, setContentScrollTop] = useState(-1);
  const [contentScrollTopKey, setContentScrollTopKey] = useState(-1);
  useEffect(() => {
    if (initLoad && tplacehome.discoveryData.tripLoading) {
      const tripEl = document.querySelector('[data-name=discovery_trip]') as HTMLDivElement;
      if (tripEl) {
        setContentScrollTop(tripEl.offsetTop + 60);
        setContentScrollTopKey(Date.now());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.discoveryData.tripLoading]);

  /**
   * logs
   */
  useEffect(() => {
    if (tplacehome.currentTab === ETPlaceTab.DISCOVERY) {
      const custom = {
        mapview: placehomeHook.getDrawerModeIndex(tplacehome.currentDrawerListMode),
        package_version: getCurrentPackageVersion(),
      };
      placehomeHook.sendExpose(custom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.currentTab]);

  /**
   * render
   */
  return (
    <ContentWrap
      hideBtnTop={!tplacehome.discoveryData.result.loaded}
      contentScrollTop={contentScrollTop}
      contentScrollTopKey={contentScrollTopKey}
    >
      <TopInfoBox>티맵 고객의 이동 데이터로 발견한 인기 장소</TopInfoBox>
      {/* 초기데이터 로딩중이거나, 내 주변에서 flicking시도하지 않은상태에서는 렌더X */}
      {!initLoad || !tplacehome.isFlickingMoved ? (
        <DiscoverySkeleton />
      ) : (
        <>
          <DiscoveryWrap>
            {contentComponentList.map((ContentComp, index) => {
              return (
                <React.Fragment key={index}>
                  {ContentComp}
                  {getAfterContentComp(index)}
                </React.Fragment>
              );
            })}
            <DiscoveryTmapAI />
          </DiscoveryWrap>
        </>
      )}
    </ContentWrap>
  );
};

const SpacerWhite = ({height = '24px'}: {height?: string}) => {
  return <div style={{height, backgroundColor: '#FFF'}} />;
};

export default Discovery;
